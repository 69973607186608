import {AjaxSync} from "../shared/ajax_utilities";
import {displayErrors} from "../shared/common";

export class EarnedRecognition {
    constructor() {
        app.EARNED_RECOGNITION = this;
    }

    setupEarnedRecognition()
    {
        app.DOM.earned = app.DOM.content.find('#er,#er_driver');
        // do nothing
        if( app.DOM.earned.length === 0 ) {
            return;
        }

        // refresh
        app.DOM.earned.find('#btn-er-refresh').on('click', (e) => {
            var $btn = $(e.currentTarget);
            // already processing ajax request
            if( $btn.hasClass('ajax') ){
                return;
            }

            // confirmation
            if( !confirm("Refreshing the Earned Recognition dashboard will re-calculate the Preparing & In Progress periods") ) {
                return;
            }

            // change button state
            $btn.addClass('ajax').removeClass('button-primary').addClass('button-grey');

            this.ErRefreshAjax();
        });

        // click <td> or <th>
        app.DOM.earned.find('td.val,th.past,th.preparing,th.inprogress').on('click', (e) => {
            window.location = app.CACHE.URL_ADMIN+$(e.currentTarget).attr('data-href');
        });

        // switch years
        app.DOM.earned.find('#opr-ear-years').on('change', (e) => {
            var $table = $(e.currentTarget).parents('div.grid-stack-item-content').find('table');
            // remove previous states
            $table.removeClass('show');
            // change selected state
            $table.filter('#tbl-opr-ear-'+e.currentTarget.value).addClass('show');
        }).trigger('change');
    }

    setupTooltipsER() {
        // do nothing
        app.DOM.earned.find('td.val[title], td.al[title]').tipso({
            useTitle: true,
            background: app.COLOUR
        });

        const $ths = app.DOM.earned.find('th.tooltip');

        $ths.filter('th.tooltip').not('.inprogress, .preparing').tipso({
            useTitle: true,
            background: '#333'
        });

        // inprogress
        $ths.filter('.inprogress').tipso({
            useTitle: true,
            background: $ths.filter('.inprogress').css('background-color')
        });

        // preparing
        $ths.filter('.preparing').tipso({
            useTitle: true,
            background: $ths.filter('.preparing').css('background-color')
        });
    }

    renderEarnedRecognitionDataIntoTable (data, year, $table) {
        // Header rows
        const header = $table.find('thead > tr');
        const body = $table.find('tbody');

        for(const i in data) {
            const row = data[i];
            let periodCss = 'completed';
            let periodTitle = 'Completed period';

            if( app.OPTIONS.current_periods.inprogress.year == year && app.OPTIONS.current_periods.inprogress.period === row.period ){
                periodCss = 'inprogress';
                periodTitle = 'In progress'
            } else if( app.OPTIONS.current_periods.preparing.year == year && app.OPTIONS.current_periods.preparing.period === row.period  ){
                periodCss = 'preparing';
                periodTitle = 'Preparing';
            } else if( parseInt(year) >= app.OPTIONS.current_periods.inprogress.year && row.period > app.OPTIONS.current_periods.inprogress.period ){
                periodCss = 'future';
                periodTitle = 'Future period';
            }

            const dtStart = moment(row.startDate);
            const dtEnd = moment(row.endDate);

            let title = `<b>${periodTitle}</b><br>From: ${dtStart.format('Do MMM YYYY')}<br>To: ${dtEnd.format('Do MMM YYYY')}`;


            const th = $('<th class="tooltip">')
                .addClass(periodCss)
                .text(`Period ${row.period}`)
                .attr('title', title)
                .attr('data-href', `er_drivers_hours/${dtStart.format('GGGG')}/${row.period}`)    // Use ISO year just in case
                .on('click', app.DASHBOARD_CORE.clickURL);

            header.append(th);

            $.each(['d1', 'd2', 'd3', 'd4', 'd5', 'd6', 'd7', 'd8', 'd9'], (k, measure) => {
                const $td = $(`<td>`).addClass(periodCss);
                if ( row[measure] ) {
                    $td.text(row[measure]).addClass('val')
                    $td.attr('data-href', `er_drivers_hours/${dtStart.format('GGGG')}/${row.period}/${measure}`);
                    $td.on('click', app.DASHBOARD_CORE.clickURL);

                    if (row[measure + '_class']) {
                        $td.addClass(row[measure + '_class']);
                    } else {
                        $td.addClass('m-green');
                    }
                } else {
                    $td.text('-');
                }

                const $row = body.find('.' + measure);
                $row.append($td);
            });
        }
    }

    displayEarYearTable(year) {
        const $targetTable = $('#tbl-opr-ear-' + year);
        $targetTable.parent().find('table').addClass('hide').removeClass('show');
        $targetTable.addClass('show').removeClass('hide');
    }

    ErRefreshAjax() {
        AjaxSync({
            type: "POST",
            url: app.CACHE.URL_AJAX+'er_refresh'
        }, {
            done: (res) => {
                if( res?.status === 'success' ) {
                    window.location.reload();
                } else {
                    displayErrors(res);
                }
            },
            fail: (err) => {
                displayErrors(err);
            },
            always: (result) => {
                $btn.removeClass('button-grey ajax').addClass('button-primary');
            }
        });
    }
}
