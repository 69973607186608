import {AjaxPromise} from "../shared/ajax_utilities";

export class DrivercheckCore {
    constructor() {
        app.DRIVERCHECK_CORE = this;
    }

    async getLicenseChecks() {
        const res = await AjaxPromise({
            url: `${app.CACHE.URL_AJAX_TPL}licence_checks`,
            method: 'POST',
            data: {
                dashboard: app.DASHBOARD_CORE.DASHBOARD,
                allowed: app.DASHBOARD_CORE.getDataGridstack(),
            }
        });

        app.DASHBOARD_CORE.paintTpl(true, res, 'usr_dri_che');
    }

    setupTooltipsLicencechecks() {
        app.DOM.filter.find('.tipso').tipso({
            useTitle: true,
            background:'#CF5757'
        });
    }
}