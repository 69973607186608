import {generateGuid} from "../../shared/common";

export class TableCore {
    TABLES = {};

    constructor() {
        app.TABLE_CORE = this;
    }

    init() {
        this.parseTables();

        if(Object.keys(this.TABLES).length > 0) {
            this.setupEvents();
        }
    }

    /**
     * We backwards parse all the tables of the page to be able to sort the
     * dashboard table data without the need to reload
     */
    parseTables(customTables) {
        const tables = app.DOM.content.find('table');
        $.each(customTables ?? tables, (key, tableEl) => {
            const tableGuid = `${generateGuid(16)}-${key}`;
            tableEl = $(tableEl);
            tableEl.attr('data-tc-id', tableGuid);
            let data = { headers: {}, content: {} };
            $.each(tableEl.find('thead tr th'), (pos, el) => {
                el = $(el);
                const tableHeaderGuid = generateGuid(5);
                data.headers[tableHeaderGuid] = pos;
                el.attr('data-tc-hid', tableHeaderGuid)
                    .attr('data-tc-id', tableGuid).html($(el).text());

                if(!el.attr('data-tc-initial')) {
                    return;
                }

                const icon = document.createElement('i');
                icon.style.paddingLeft = '5px';
                icon.style.marginTop = '2px';
                if(el.attr('data-tc-initial') === 'asc') {
                    icon.className = 'fa fa-circle-arrow-up';
                } else if(el.attr('data-tc-initial') === 'desc') {
                    icon.className = 'fa fa-circle-arrow-down';
                }

                el.append(icon);
            });

            $.each(tableEl.find('tbody tr'), (k, tableRow) => {
                let rowData = {};
                $.each($(tableRow).find('td'), (pos, cell) => {
                    cell = $(cell);
                    rowData[pos] = {
                        html: cell.html(),
                        text: cell.text().replaceAll('\n', '').trim()
                    };
                });

                const rowGuid = `${generateGuid(6)}-${k}`;
                data.content[rowGuid] = rowData;
                $(tableRow).attr('data-tc-rid', rowGuid);
            });

            this.TABLES[tableGuid] = data;
        });
    }

    setupEvents() {
        $('.sortable').unbind('click').on('click', (e) => {
            e.preventDefault();
            try {
                const headerEl = $(e.currentTarget);
                if (!headerEl.attr('data-tc-id') || !headerEl.attr('data-tc-hid')) {
                    return;
                }

                const tableId = headerEl.attr('data-tc-id');
                const headerId = headerEl.attr('data-tc-hid');
                const colPos = this.TABLES[tableId].headers[headerId];
                let data = this.flattenRow(colPos, this.TABLES[tableId].content);
                const sortType = headerEl.attr('data-tc-sort-type') ?? 'text';
                switch (headerEl.attr('data-tc-order')) {
                    case 'asc':
                        headerEl.removeAttr('data-tc-order');
                        break;
                    case 'desc':
                        headerEl.attr('data-tc-order', 'asc');
                        data = this.sort(data, 'asc', sortType);
                        break;
                    default:
                        headerEl.attr('data-tc-order', 'desc');
                        data = this.sort(data, 'desc', sortType);
                        break;
                }

                if (data && data.forEach) {
                    this.redrawTable(tableId, data);

                    let iconTag = headerEl.find('i');
                    if (!iconTag || iconTag.length === 0) {
                        const icon = document.createElement('i');
                        icon.style.paddingLeft = '5px';
                        icon.style.marginTop = '2px';
                        headerEl.append(icon);
                        iconTag = headerEl.find('i');
                    }

                    app.DOM.content.find(`table[data-tc-id="${tableId}"] thead i`).removeClass('fa fa-circle-arrow-down fa-circle-arrow-up');
                    switch (headerEl.attr('data-tc-order')) {
                        case 'asc':
                            iconTag.addClass('fa fa-circle-arrow-up');
                            break;
                        case 'desc':
                            iconTag.addClass('fa fa-circle-arrow-down');
                            break;
                    }
                }
            } catch(err) {
                console.log(err);
            }
        });
    }

    flattenRow(colPos, data) {
        let flattenedCol = [];
        Object.keys(data).forEach((row) => {
            const rowEl = $(`tr[data-tc-rid="${row}"]`);
            const colEl = $(rowEl.find('td')[colPos]);
            let rowData = {
                ...data[row][colPos],
                rowKey: row,
                data: '',
            };

            if(colEl && colEl.length > 0) {
                rowData.data = colEl.attr('data');
            }

            flattenedCol.push(rowData);
        });
        return flattenedCol;
    }

    sort(data, order, sortType) {
        switch(sortType) {
            case 'tacho_severity':
                data = this.tachoSeveritySort(data, order);
                break;
            case 'data_attr':
                data = this.dataAttrSort(data, order);
                break;
            case 'currency':
                data = this.currencySort(data, order);
                break;
            case 'text':
            default:
                data = this.textSort(data, order);
                break;
        }

        return data;
    }

    tachoSeveritySort(toSort, order) {
        switch(order) {
            case 'asc':
            case 'desc':
                return toSort.sort((a, b) => {
                    if(this.parseTachoSeverity(a.text) > this.parseTachoSeverity(b.text)) return order === 'asc' ? 1 : -1;
                    if(this.parseTachoSeverity(b.text) > this.parseTachoSeverity(a.text)) return order === 'asc' ? -1 : 1;
                    return 0;
                });
            default: return toSort;
        }
    }

    dataAttrSort(toSort, order) {
        switch(order) {
            case 'asc':
            case 'desc':
                return toSort.sort((a, b) => {
                    if(a.data > b.data) return order === 'asc' ? 1 : -1;
                    if(b.data > a.data) return order === 'asc' ? -1 : 1;
                    return 0;
                });
            default: return toSort;
        }
    }

    currencySort(toSort, order) {
        const parsed = toSort.map((currency) => {
            currency.text = currency.text.trim().split(' ')[0];
            currency.text = currency.text.replace(app.CACHE.USER.opr_currency_unit ?? '£', '').replaceAll(',', '').trim();
            currency.text = parseFloat(currency.text);
            return currency;
        });

        switch(order) {
            case 'asc':
            case 'desc':
                return parsed.sort((a, b) => {
                    if(a.text > b.text) return order === 'asc' ? 1 : -1;
                    if(b.text > a.text) return order === 'asc' ? -1 : 1;
                    return 0;
                });
            default: return toSort;
        }
    }

    textSort(toSort, order) {
        switch(order) {
            case 'asc':
            case 'desc':
                return toSort.sort((a, b) => {
                    if(a.text > b.text) return order === 'asc' ? 1 : -1;
                    if(b.text > a.text) return order === 'asc' ? -1 : 1;
                    return 0;
                });
            default: return toSort;
        }
    }

    parseTachoSeverity(text) {
        switch(text.toLowerCase().trim()) {
            case 'most serious infringement': return 3;
            case 'very serious infringement': return 2;
            case 'serious infringement': return 1;
            case 'minor infringement': return 0;
        }
    }

    redrawTable(tableId, data) {
        const table = app.DOM.content.find(`table[data-tc-id="${tableId}"]`);
        let newOrder = [];
        const tbody = table.find('tbody');
        data.forEach((row) => {
            newOrder.push(tbody.find(`tr[data-tc-rid="${row.rowKey}"]`).clone(true));
        });
        tbody.html('');
        newOrder.forEach((el) => {
            tbody.append(el);
        });
        app.DASHBOARD_CORE.populateAdditionalRows();
    }
}